<template>
<div class="score-list">
  <div class="search">
    <div class="search-item">
      <div class="name">
        类型筛选:
      </div>
      <div class="cont">
        <div class="values">
          <div :class="curType == item.id ? 'active' : ''" v-for="(item,index) in cycleTypeList" :key="index + 'category'" @click="changeType(item.id)">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
  <Table border :columns="columns" :loading="loading" :data="data"></Table>
  <DeleModal :status="deleModalStatus" txt="确定下架吗？" btnTxt="确定" :loading="modalLoading" @cancel="cancelOffline" @confirm="confirmOffline"></DeleModal>
</div>
</template>

<script>
import DeleModal from '@/components/deleteModal.vue';
export default {
  name: "scoreList",
  data(){
    return {
      curType:'-1',
      columns:[
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '积分名称',
          key: 'name',
        },
        {
          title: '代号',
          key: 'code',
          render: (h, para) => {
            return h('div', {
            }, this.codeTranslates[para.row.code]);
          },
        },
        {
          title: '任务类型',
          key: 'cycle_type',
          render: (h, para) => {
            return h('div', {
            }, this.cycleTypes[para.row.cycle_type]);
          },
        },
        {
          title: '获得积分',
          key: 'score',
        },
        {
          title: '总完成人数',
          key: 'user_count',
        },
        {
          title: '总完成次数',
          key: 'total_count',
        },
        {
          title: '状态',
          key: 'status',
          render: (h, para) => {
            return h('div', {
            }, this.statuses[para.row.status]);
          },
        },
        {
          title: '操作',
          key: 'id',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    this.edit(params.row)

                  }
                }
              }, '编辑'),
              params.row.status == 0 ? h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.deleModalStatus = true;
                  }
                }
              }, '下架') : h('Button', {
                props: {
                  size: 'small',
                  type: 'text',
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    this.onLine(params.row);
                  }
                }
              }, '上架 '),
            ]);
          }
        },
      ],
      loading:false,
      data:[],
      cycleTypes:{},
      cycleTypeList:[],
      statuses:{},
      curData:{},
      deleModalStatus:false,
      modalLoading:false,
      codeTranslates:{},
    }
  },
  components:{
    DeleModal
  },
  created(){
    this.getList();
  },
  methods:{
    changeType(id){
      this.curType = id;
      this.getList();
    },
    getList(){
      let params = {
        status:'-1',
        cycle_type:this.curType
      }
      this.loading = true;
      this.api.score.taskList(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
        this.cycleTypes = res.cycleTypes;
        this.cycleTypeList = [
          {
            name:'全部',
            id:'-1'
          }
        ]
        for(let name in res.cycleTypes){
          this.cycleTypeList.push({
            id:name,
            name:res.cycleTypes[name]
          })
        }
        this.statuses = res.statuses;
        this.codeTranslates = res.codeTranslates;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    edit(data){
      this.curData = data;
      this.$router.push({
        path:'/score/edit',
        query:{
          id:this.curData.id
        }
      })
    },
    cancelOffline(){
      this.deleModalStatus = false;
    },
    confirmOffline(){
      let data = {
        id:this.curData.id
      }
      this.modalLoading = true;
      this.api.score.taskOffline(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });

    },
    onLine(data){
      this.curData = data;
      let params = {
        id:this.curData.id
      }
      this.api.score.taskOnline(params).then((res)=>{
        this.$Message.success('操作成功');
        this.getList();
      });
    }
  }
}
</script>

<style scoped lang="scss">
.score-list{
  .search{
    margin-top: 20px;
    //margin-bottom: 20px;
    font-size: 14px;
    color:#333333;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;

      .name{
        padding: 5px 0;
        text-align: right;
        font-weight: bold;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 0 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            font-weight: bold;
            color: #4A79FF;
          }
        }
      }
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
