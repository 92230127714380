<template>
<div class="exchange">
  <Button type="primary" @click="add">+ 添加兑换</Button>
  <div class="table">
    <Table border :columns="columns" :loading="loading" :data="data"></Table>
  </div>
  <div class="page">
    <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
  </div>
  <Modal
      v-model="modal"
      title="补充库存"
      width="800px"
      @on-ok="ok"
      @on-cancel="cancel">
      <div class="upload-modal">
        <div class="upload-modal-tit">上传激活码：</div>
        <div>若为刷题激活码，请先前往平台管理-激活码列表中，生成批量自主分发激活码；</div>
        <div class="upload-modal-file">
          <Button type="primary">上传文件</Button>
          <p>上传后的文件名称.exe</p>
        </div>
        <div class="upload-modal-tem">下载模版</div>
        <div class="upload-modal-tit mt20">库存数量（跟随上传文件识别显示数量，不可编辑）</div>
        <div class="upload-modal-num">56</div>
      </div>
  </Modal>
</div>
</template>

<script>
export default {
  name: "exchange.vue",
  data(){
    return{
      total:0,
      page:1,
      pageSize:10,
      loading:false,
      columns:[
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '兑换名称',
          key: 'id',
        },
        {
          title: '所需积分',
          key: 'id',
        },
        {
          title: '类型',
          key: 'id',
        },
        {
          title: '剩余库存',
          key: 'id',
        },
        {
          title: '兑换人数',
          key: 'id',
        },
        {
          title: '创建时间',
          key: 'id',
        },
        {
          title: '状态',
          key: 'id',
        },
        {
          title: '操作',
          key: 'id',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)

                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)

                  }
                }
              }, '补充库存'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)

                  }
                }
              }, '上传文件'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)

                  }
                }
              }, '兑换详情'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)

                  }
                }
              }, '下架'),
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginTop: '5px',
                  marginRight: '5px',
                  color:'#4E83FF'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)

                  }
                }
              }, '上架'),
            ]);
          }
        },
      ],
      data:[],
      modal:false,
    }
  },
  methods:{
    getList(){

    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    add(){
      // this.$router.push({
      //   path:'/score/editExchange'
      // })
      this.$router.push({
        path:'/score/exchangeDetail'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.exchange{
  margin-top: 20px;
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.upload-modal{
  font-size: 14px;
  .upload-modal-tit{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color:#4A79FF;
  }
  .upload-modal-file{
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >p{
      margin-left: 20px;
    }
  }
  .upload-modal-tem{
    margin-top: 10px;
    text-decoration:underline
  }
  .upload-modal-num{
    font-size: 14px;
    width: 100px;
    height: 40px;
    background-color: #f8f8f9;
    text-align: center;
    line-height: 40px;
  }
}
</style>
