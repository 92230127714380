<template>
<div class="score">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="score-cont">
    <div class="score-cont-tab">
      <div class="score-cont-tab-l">
        <p :class="curTab == index ? 'active' : ''" v-for="(item,index) in tabList" :key="index" @click="changeTab(index)">{{ item.name }}</p>
      </div>
    </div>
    <ScoreList v-if="curTab== 0"></ScoreList>
    <Exchange v-if="curTab== 1"></Exchange>
  </div>
</div>
</template>

<script>
import ScoreList from './components/scoreList'
import Exchange from './components/exchange'
export default {
  name: "index",
  data(){
    return{
      tabList:[
        {
          name:'积分项管理',
          id:0
        },
        // {
        //   name:'兑换管理',
        //   id:1
        // },
      ],
      curTab:0,

    }
  },
  components:{
    ScoreList,
    Exchange
  },
  methods:{
    changeTab(index){
      this.curTab = index;
    },
    changeType(id){
      this.curType = id;
    }
  }
}
</script>

<style scoped lang="scss">
  .score{
    margin: 20px;
    font-size: 14px;
    .score-cont{
      padding:20px;
      background: #FFFFFF;
      box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
      border-radius: 4px;


      .score-cont-tab{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EEEEEE;
        height: 40px;

        .score-cont-tab-l{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          font-size: 16px;
          color: #666666;
          >p{
            padding-top: 6px;
            margin-right: 28px;
            height: 100%;
            cursor: pointer;
          }
          .active{
            font-weight: bold;
            color: #4A79FF;
            border-bottom: 4px solid #5782FF;;
          }
        }
      }
    }
  }
</style>
